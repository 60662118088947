import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ContactMe from "./pages/ContactMe";
import BlogPosts from "./pages/BlogPosts";
import Post from "./pages/Post";
import AboutMe from "./pages/AboutMe";
import DearYou from "./components/DearYou";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import "./App.scss";
import ReactGA from "react-ga";
ReactGA.initialize(process.env.REACT_APP_GA_ID);
history.listen((location) => {
  // console.log(window.location.pathname);
  ReactGA.pageview(window.location.pathname);
});
const client = new ApolloClient({
  uri: "https://journey-to-stability.herokuapp.com/admin/api",
  cache: new InMemoryCache(),
});

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <div className="yesi-blog">
          <Router history={history}>
            <React.Fragment>
              <NavBar />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/contact-me" component={ContactMe} />
                <Route exact path="/blog-posts" component={BlogPosts} />
                <Route exact path="/blog-posts/:id" component={Post} />
                <Route exact path="/about-me" component={AboutMe} />
                <Route exact path="/dear-you" component={DearYou} />
              </Switch>
              <Footer />
            </React.Fragment>
          </Router>
        </div>
      </ApolloProvider>
    );
  }
}

export default App;
