import React from 'react'
import {Container, Row, Col} from "reactstrap";
import "./BlogPostPreview.scss"
const BlogPostPreview = (props) => {
  return (
    <div className="blog-post-preview" >
    <Container>
    <Row  className="full-preview">
        {/* <Col xs="2"></Col>    */}
        {/* IMAGE PREVIEW */}

        <Col className="preview-image" sm="6" xs={{size:"auto"}}>
        <a href={`/blog-posts/${props.id}`}>
        <img  id={props.id} src={props.headerImage} alt="header"/>
        </a>
        </Col>
        {/* POST PREVIEW DETAILS */}
        <Col  className="preview-details" sm="6" xs={{size:"auto"}}>
        <a href={`/blog-posts/${props.id}`}><h2>{props.postTitle}</h2></a>
        <div className="author-date">
            <p>Yesi  <span>{props.postDate}</span></p>
            
        </div>
        <p>{props.description}</p>

        </Col>
        
        {/* <Col xs="2"/> */}
    </Row>

    </Container>
    <hr/>
  </div>
  )
}

export default BlogPostPreview
