import React from "react";
import "./HomeAboutMe.scss";
import { Container, Row, Col, Button } from "reactstrap";
import HomeAboutHero from "../../assets/images/home-about-hero.jpeg";

const HomeAboutMe = () => {
  return (
    <div className="home-about-me">
      <Container>
        <Row>
          <Col xs="4">
            <figure className="hero-img">
              <img src={HomeAboutHero} alt="hero" />
            </figure>
            <div className="social-icons">
              <a href="https://www.instagram.com/journey.to.stability/">
                <span>
                  <i className="fab fa-instagram" aria-hidden="true"></i>
                </span>
              </a>
              {/* <a href="/#"><span><i className="fab fa-youtube-square" aria-hidden="true"></i></span></a> */}
              <a href="/#">
                <span>
                  <i className="fab fa-facebook-square" aria-hidden="true"></i>
                </span>
              </a>
              <a href="https://twitter.com/YesiCastelan">
                <span>
                  <i className="fab fa-twitter-square" aria-hidden="true"></i>
                </span>
              </a>
            </div>
          </Col>
          <Col xs="8">
            <div className="home-about-content">
              <h3>Hello, I'm Yesi</h3>
              <p>
                I am a 22 year old trying navigate life with mental illness
                diagnosis. Life has been a challenge but I am ready to start
                making changes to start building a life worth living. I want to
                invite you as embark on this journey to stability. There will be
                ups, downs, and everything in between. I want to share what I
                have experienced, what I am learning along the way, and maybe
                something along the way might help someone.
              </p>
              <Button href="/about-me" outline color="primary">
                Learn More
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeAboutMe;
