import React from 'react'
import "./DearYou.scss"
const DearYou = () => {
  return (
    <div className="dear-you">
      
      <div className="dear-you-content">
      <h2>Dear You,</h2>
      <p>
    Life is hard. I know I don’t have to tell you this. I don’t have to remind you, but I do want to validate the weight behind this statement. Sometimes the idea of continuing in a world that has caused so much pain and hurt seems so insurmountable. How can you continue in a world you feel you were not made for? You feel weak and your mind tells you every reason why you are not worthy. You hurt. The pain is mental and physical. It travels through your whole body. It’s a disease. It starts in one place and doesn’t stop until it has enveloped your entire body. You are engulfed in this hurt and illness. Some days choosing to live is the hardest thing you do.
      </p>
      <p>
      You probably wonder what is the point? Why hold on? I know. I ask myself those questions constantly. I don’t have a perfect answer, I don’t. I can tell you that I have been in this boat for seven years. I can tell you that I have hope. Some days that hope is bigger than others. Some days I really have to search in order to find it, but I have hope it doesn’t always have to be this way. I have hope that one day I’ll be able to live a life worth living. I hope I’ll find stability and find something to enjoy in life. I hope to find my place in this world. I will one day discover who I really am. I have hope and in the meantime I make small steps. I make progress and it isn’t always linear. I learn how to cope and manage the symptoms of my illness. One day I will build the life worth living I want. If you haven’t yet, you have the power to do so, too. Join me on my journey to conquer this thing called life and it’s obstacles. Let’s hold on to the little bit longer to this hope together and head on this journey to stability.
      <br/>
      <br/>
Love, 
Yesi

      </p>
      </div>
    </div>
  )
}

export default DearYou
