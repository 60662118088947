import React from 'react'
import "./TitleParallax.scss";
import {Container, Jumbotron} from "reactstrap";
const TitleParallax = () => {
  return (
    <div className="title-parallax">
       <div className="parallax"></div>
        <Jumbotron>
        <Container>
          <h1 className="display-3">Journey to Stability</h1>
          <p className="lead">Building A Life Worth Living</p>
        </Container>
      </Jumbotron>
    </div>
    
  )
}

export default TitleParallax
