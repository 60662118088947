import React, { Component } from "react";

import { format, parseISO } from "date-fns";
import { useQuery, gql } from "@apollo/client";
import BlogPostPreview from "../../components/BlogPostPreview";
import Loader from "../../components/Loader";

const ALL_POSTS = gql`
  query {
    allPosts(where: { status: published }, sortBy: publishDate_DESC) {
      id
      title
      headerImage {
        publicUrlTransformed
      }
      publishDate
      description
      url
    }
  }
`;

// TODO (New Feature) : Add Sort By Functionality
// TODO Add Error Component
const BlogPosts = () => {
  const { loading, error, data } = useQuery(ALL_POSTS);

  return (
    <div className="blog-posts">
      {loading === true ? (
        <Loader />
      ) : (
        <React.Fragment>
          {data.allPosts.map((posts, i) => (
            <BlogPostPreview
              key={posts.id}
              headerImage={posts.headerImage.publicUrlTransformed}
              postTitle={posts.title}
              postDate={format(parseISO(posts.publishDate), "MM.dd.yyyy")}
              description={posts.description}
              id={posts.url}
            />
          ))}
        </React.Fragment>
      )}
    </div>
  );
};

export default BlogPosts;
