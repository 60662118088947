import React from 'react'
import "./NavBar.scss";
import {Navbar, NavbarBrand,Collapse, NavbarToggler,Nav, NavItem, NavLink} from "reactstrap";
import {Link} from "react-router-dom";

class NavBar extends React.Component {
  constructor(){
    super();
    this.state={
      viewPortSize : 0,
      collapsed: true
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.closeCollapse=this.closeCollapse.bind(this);
  }
 


componentDidMount() {
  this.updateWindowDimensions();
  window.addEventListener('resize', this.updateWindowDimensions);
}

updateWindowDimensions() {
  this.setState({ viewPortSize: window.innerWidth});
}

toggleNavbar() {
  this.setState({
    collapsed: !this.state.collapsed
  });
}
closeCollapse(){
  this.setState({
    collapsed: true
  })
}
  
  render() {

    
    return (
      <React.Fragment>
        {this.state.viewPortSize > 755 ? 
        <Navbar>
        <NavbarBrand href="/">Journey to Stability</NavbarBrand>
        <div className="nav-links">
          <Link className="nav-link" to= "/about-me">About Me</Link>
          <Link className="nav-link" to= "/dear-you">Dear You</Link>
           <Link className="nav-link" to="/blog-posts">Blog Posts</Link>
          {/* <Link className="nav-link" to= "/videos">Videos</Link>
          <Link className="nav-link" to="/statistics">Statistics</Link> */}
          <Link className="nav-link" to= "/contact-me">Contact Me</Link>
        </div>
    </Navbar>
        :
        <div className="collapse-menu">
        <Navbar dark>
        <NavbarBrand href="/">Journey to Stability</NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2"/>
          <Collapse isOpen={!this.state.collapsed} navbar>
            <Nav navbar>
              <NavItem>
                <Link onClick ={this.closeCollapse} className="nav-link" to="/about-me">About Me</Link>
              </NavItem>
              <NavItem>
              <Link onClick ={this.closeCollapse} className="nav-link" to="/dear-you">Dear You</Link>
              </NavItem>
              <NavItem>
              <Link onClick ={this.closeCollapse} className="nav-link" to="/blog-posts">Blog Posts</Link>
              </NavItem>
              <NavItem>
              <Link onClick ={this.closeCollapse} className="nav-link" to="/contact-me">Contact Me</Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
        }
        
    </React.Fragment>
    )
  }
}

export default NavBar
