import React, { Component } from "react";
import "./AboutMe.scss";

export class AboutMe extends Component {
  render() {
    return (
      <div className="about-me">
        <h1>About Me</h1>
        <img
          src="https://res.cloudinary.com/dz2qkeerl/image/upload/v1573932165/Blog/AboutMe11-16.jpg"
          alt="Yesi selfie wearing black hat"
        />
        <p className="content">
          <strong>Yes, it's Yesi</strong>
          <br />
          My name is Yesenia Castelan but I go by Yesi. Yesenia means flower,
          and flowers are usually associated with being positive. Although
          Yesenia is a beautiful name, growing up family called me Yesi and when
          I got into school I realized Yesenia was hard for some to pronounce so
          Yesi it was. I currently work at a grocery store and my name is the
          thing I get comments on the most. One day I tried to count and I had
          about 20 comments in about two hours. One of my most received comment
          is, “Oh wow your name is Yes..si. It’s yes in two languages. You must
          be such a positive person.” I always thought it was funny that there
          is so much positivity associated with my name because I don’t feel
          positive a lot of the time. <br />
          <br />
          <strong>Who Am I</strong> <br />I am a 22 Year old living in Texas. I
          love to write, listen to music, watch Hulu and Netflix, I love Demi
          Lovato, Lady Gaga, Ellen, listening to my brother tell awful puns,
          watching cartoons with my sister, and going on family dinners. I work
          part time and I have been sick full time. I am ready to move forward
          and start experiencing life. For seven years now I have been dealing
          with my mental health. It has become my identity; it is all I know. It
          started when I was in 7th grade and then my sophomore year of high
          school my mental health deteriorated. I had to go inpatient to a
          psychiatric hospital. I ended heading down a road of hospitalizations,
          tears, and lost hope. It is now four years later from my first
          hospital stay and I have accumulated my fair share of emergency
          department and psych hospital stays. I have been diagnosed with
          borderline personality,bipolar disorder and PTSD. Learning to cope
          with these conditions has been very difficult for me. There are
          moments in my life when I feel like there is no hope and life is
          impossible, but everyday I learn that this is not true. I know it is
          not easy, but it is possible. I am making progress, but progress is
          not linear. Progress is slow and it’s sometimes painful. This all a
          journey for me, a journey to stability. <br />
          <br />
          {/* *****Stable definition and synonyms going across screen maybe???*****

“Enduring or permanent, firm, not easily upset or disturbed.Balanced” */}
          <strong>Journey to Stability</strong>
          <br />
          Healing and recovery are a journey. Everyone heals at different speeds
          and people take different paths. Not everyone’s journey looks the
          same, but we can learn from each other. I want to build a life worth
          living where I feel stable. I want to begin experiencing life because
          when being sick is your full time job and identity you don’t get to. I
          want to find what fuels my fire, what my passion is and pursue it. I
          want to find little things to make my everyday life worth living and I
          want you to join me as I start living, as I find myself, and as I find
          stability. <br />
          <br />
          <strong>What to expect</strong>
          <br />
          One of my favorite mantras is , “Everything is temporary” or “This too
          shall pass.” It helps me get through the intense, uncomfortable
          emotions and it helps me appreciate and hold on to the good ones.
          There will be great moments, but they will pass and when they do the
          bad moments sometimes seem to last forever. It’s hard to believe that
          they are temporary but they are. I want to show you the ups, the
          downs, and the inbetweens. It won’t always be pretty, but I want to
          show you that it’s possible to get through it and how I got through
          it. Maybe it will spark an idea or maybe just some hope. I want to
          show my story, my journey. There will be coping skills, me trying new
          things, venting, motivational writings, and much more.
          <br />
          <i>I hope to see you again! </i>
        </p>
      </div>
    );
  }
}

export default AboutMe;
