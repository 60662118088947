import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "./Footer.scss";
class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="container-top-footer">
          <Row className="footer-top">
            <Col xs="4" className="footer-title-col">
              <div className="footer-title"> Journey to Stability </div>
              <div className="footer-tagline">
                {" "}
                Building a Life Worth Living
              </div>
            </Col>
            <Col xs="4" className="navigation">
              <ul className="left">
                <li>
                  <Link className="nav-link" to="/about-me">
                    {" "}
                    About Me
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/dear-you">
                    Dear You
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/blog-posts">
                    Blog Posts
                  </Link>
                </li>
                {/* <li><Link className="nav-link" to= "/videos"> Videos </Link></li> */}
                {/* <li><Link className="nav-link" to="/statistics">Statistics</Link></li> */}
                <li>
                  <Link className="nav-link" to="/contact-me">
                    Contact Me
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xs="4" className="social-media">
              <header>Follow Me</header>
              <a href="https://www.instagram.com/journey.to.stability/">
                <span>
                  <i className="fab fa-instagram " aria-hidden="true"></i>
                </span>
                &nbsp;&nbsp;Instagram
              </a>
              {/* <a href="/#"><span><i className="fab fa-youtube-square " aria-hidden="true"></i></span>&nbsp;&nbsp;Youtube</a> */}
              <a href="/#">
                <span>
                  <i className="fab fa-facebook-square " aria-hidden="true"></i>
                </span>
                &nbsp;&nbsp;Facebook
              </a>
              <a href="https://twitter.com/YesiCastelan">
                <span>
                  <i className="fab fa-twitter-square " aria-hidden="true"></i>
                </span>
                &nbsp;&nbsp;Twitter
              </a>
            </Col>
          </Row>
        </div>
        <Row className="footer-bottom">
          <Col>
            {" "}
            © 2021 Journey to Stability • Developed by{" "}
            <a href="https://www.alvarocastelan.com"> Alvaro Castelan</a>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
