import React, { Component } from "react";
import "./Post.scss";
import { useQuery, gql } from "@apollo/client";
import { format, parseISO } from "date-fns";
import { Row, Col } from "reactstrap";
import Loader from "../../components/Loader";
// import { useParams } from "react-router-dom";
import ReactGA from "react-ga";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const SINGLE_POST_QUERY = gql`
  query SINGLE_POST_QUERY($url: String) {
    allPosts(where: { url: $url }) {
      title
      id
      headerImage {
        publicUrlTransformed
      }
      url
      body
      publishDate
    }
  }
`;

const Post = () => {
  // need to get route location
  // console.log("window location name", window.location.pathname);
  const pathName = window.location.pathname;
  const splittingPathName = pathName.split("/");
  const url = splittingPathName[2];
  // console.log("Slug", blogSlug);
  // need to update below reactGA
  // ReactGA.pageview(window.location.pathname)

  const { loading, error, data } = useQuery(SINGLE_POST_QUERY, {
    variables: { url },
  });
  // console.log("loading", loading);
  // console.log("data", data.allPosts);
  if (error) {
    // console.log(error);
    return <div>Error</div>;
  }
  // TODO: Add Error Component
  if (loading) {
    return <Loader />;
  }
  return (
    <div className="post">
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          {data.allPosts.map((post, i) => (
            <div key={post.id} className="full-blog-post">
              <h1>{post.title}</h1>
              <img src={post.headerImage.publicUrlTransformed} alt="header" />
              <div className="author-date">
                <p>
                  By Yesi{" "}
                  <span>
                    {" "}
                    {format(parseISO(post.publishDate), "MM.dd.yyyy")}
                  </span>
                </p>
              </div>
              <p
                className="body"
                dangerouslySetInnerHTML={{ __html: post.body }}
              ></p>
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default Post;
