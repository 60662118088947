import React from 'react'
import "./Loader.scss"
const Loader = () => {
  return (
    <div className="loader">

    <div id="preloader">
        <div id="loader"></div>
    </div>

</div>
  )
}

export default Loader