import React, { Component } from "react";
import TitleParallax from "../../components/TitleParallax";
import HomeAboutMe from "../../components/HomeAboutMe";
import DearYou from "../../components/DearYou";
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_GA_ID);
ReactGA.pageview("homepage");

export class Home extends Component {
  render() {
    return (
      <div className="home-page">
        <section className="section-1">
          <TitleParallax />
        </section>
        <section className="section-2">
          <HomeAboutMe />
        </section>
        <section className="section-3">
          <DearYou />
        </section>
      </div>
    );
  }
}

export default Home;
